<template>
  <div class="about" v-if="is_show">
      <el-row :gutter="20" style="min-width:879px">
        <el-col :span="16" style="margin:10px 0">
          <div class="car">
            <el-card  shadow="none">
              <!--                <div slot="header" class="clearfix">-->
              <!--                  <span style="color: #4d4d4d">统计</span>-->
              <!--                </div>-->
              <el-row :gutter="10">
                <el-col class="mincar" :span="24" >
                  【{{nowdate}}】统计
                </el-col>
                <el-col :span="8" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">用户</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{dateFrome.member}}</h2>
                  </el-card>
                </el-col>

                <el-col :span="8" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">师傅</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{dateFrome.worker}}</h2>
                  </el-card>
                </el-col>

                <el-col :span="8" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">订单</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{dateFrome.order}}</h2>
                  </el-card>
                </el-col>
                <el-col class="mincar" :span="24" >
                  用户统计
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">今天</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.member_today}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">本周</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.member_week}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">本月</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.member_month}}</h2>
                  </el-card>
                </el-col>
                <el-col class="mincar" :span="6"  style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">总计</span>
                    </div>
                    <h2 style="color: #e98000;text-align: center;margin: 8px 0">{{ruleFrome.member_count}}</h2>
                  </el-card>
                </el-col>
                <el-col class="mincar" :span="24" >
                  师傅统计
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">今天</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.worker_today}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">本周</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.worker_week}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">本月</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.worker_month}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">总计</span>
                    </div>
                    <h2 style="color: #e98000;text-align: center;margin: 8px 0">{{ruleFrome.worker_count}}</h2>
                  </el-card>
                </el-col>

                <el-col class="mincar" :span="24" >
                  订单统计
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">今天</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.order_today}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">本周</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.order_week}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">本月</span>
                    </div>
                    <h2 style="color: #00a0e9;text-align: center;margin: 8px 0">{{ruleFrome.order_month}}</h2>
                  </el-card>
                </el-col>
                <el-col :span="6" class="mincar" style="margin:10px 0">
                  <el-card shadow="hover">
                    <div slot="" class="clearfix">
                      <span class="textcolo">总计</span>
                    </div>
                    <h2 style="color: #e98000;text-align: center;margin: 8px 0">{{ruleFrome.order_count}}</h2>
                  </el-card>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-col>
        <el-col :span="8"  style="margin:10px 0">
          <div class="car">
            <el-card shadow="none">
              <div style="width: 100%">
                <div class="index_on_1" style="height: 56vh">
                  <el-calendar @click="serch(value)" v-model="value">
                  </el-calendar>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
<!--        <el-col :span="16" style="margin:10px 0">-->
<!--          <div class="car">-->
<!--            <el-card>-->
<!--              <el-row :gutter="10">-->

<!--              </el-row>-->
<!--            </el-card>-->
<!--          </div>-->
<!--        </el-col>-->
      </el-row>
  </div>
</template>
<script>
// @ is an alias to /src
import {getHomeData} from "@/api/common"
import {get,post} from "@/api/Api"
import md5 from 'js-md5';
import * as dayjs from "dayjs";
export default {
  name: 'about',
  data(){
    return{
      value:"",
      nowdate:"",
      ruleFrome:{

      },
      dateFrome:{
        member:0,
        order:0,
        worker:0,
      },
        is_show:false
    }
  },
  created() {
    this.tabsLogin()
    this.serch(new Date());
    this.onGetShow();
  },
  methods:{
    serch(value){
      console.log(value)
      let date=dayjs(value).format('YYYY-MM-DD')
      this.nowdate=date
      get({create_time:date},'/api/admin/searchStatis').then((res) => {
        this.dateFrome=res.data
      });
    },
    tabsLogin() {
      getHomeData('').then((res) => {
        this.ruleFrome=res.data
      });
    },
      //是否展示
      onGetShow()
      {
          get({},'/api/admin/getPermiss').then((res) => {
              if (res.code === 200){
                  this.is_show = res.data;
              }
          });
      }
  }
}
</script>
<style lang="less" >
.el-card__header{
  padding: 4px 10px;
}
.car .el-card__body{

}
.mincar .el-card__body{
  padding: 5px!important;
  /*background-color: #fcfcfc;*/
}
.index_on_1{
  padding: 3px;
  /*border-radius: 3px;*/
  width: 99%;
  min-width: 230px;
  height: 300px;
  /*border: 1px solid #d9d9d9;*/
  margin-right: 10px;
  font-size: 12px;
}
.el-calendar__header{
  padding: 2px!important;
}
.el-calendar__body{
  padding: 0!important;
}
.el-calendar-table .el-calendar-day{
  height: 30px!important;
}
.index_on_1 .el-button--mini, .el-button--mini.is-round{
  padding: 3px;
}
.textcolo{
  color: #ac9c9c;    padding: 6px 26px;display: inline-block;
}
.mincar>h2{
  font-size:30px!important; ;
}
</style>